import React from "react";
import HathaYoga from "../assets/img/AdobeStock_657314659.webp";
import { HashLink } from "react-router-hash-link";

function News({ isMobile }) {
  return (
    <div className={isMobile ? "padding-inline" : "news-wrapper"}>
      <div className="fs-primary-heading">Aktuelles.</div>
      <div className="news-list">
        <div className="news-list-item">
          <img className="news-list-item-img" src={HathaYoga} alt="Yoga"></img>
          <div className="news-list-item-content">
            <div className="news-list-item-header">
              Präventionskurs Hatha-Yoga
            </div>

            <div className="news-list-item-date">
              Neuer Kurs startet am 06.05.2025: Anmeldungen ab sofort möglich!
            </div>

            <p className="news-list-item-text">
              Es ist eine Form des Yoga, bei der das Gleichgewicht zwischen
              Körper und Geist angestrebt wird. Körperübungen, Atemübungen und
              Entspannungsübungen wirken ausgleichend auf unser Nervensystem.
            </p>
            <ul className="news-list-item-list">
              <li className="news-list-item-list-item">
                10 Einheiten a 75 min. im Handlungsfeld Stressmanagment und
                Entspannung
              </li>
              <li className="news-list-item-list-item">
                Kurs ist krankenkassenzertifiziert und wird von den
                Krankenkassen bezuschusst
              </li>
              <li className="news-list-item-list-item">
                Jeder Teilnehmer erhält nach Abschluss eine Teilnahmebestätigung
              </li>
            </ul>
            <HashLink to={"/kurse#kursplan"} className="news-list-item-link">
              ZUM KURSPLAN
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
